/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 84번째 로그에서는 네이버 통장 출시, 클럽하우스, 노션 퍼스널 플랜 무료, MS 빌드 2020 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "네이버 통장 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://campaign.naver.com/npay/naver-account/"
  }, "네이버통장")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.kakaobank.com/Notices/view/10783"
  }, "공지사항 | 카카오뱅크")), "\n"), "\n", React.createElement(_components.h3, null, "클럽하우스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.forbes.com/sites/alexkonrad/2020/05/15/andreessen-horowitz-wins-vc-sweepstakes-to-back-clubhouse-voice-app/#5c60324c6f2a"
  }, "Andreessen Horowitz Wins VC Sweepstakes To Back Clubhouse, Voice App Still In Beta, At $100 Million Valuation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.axios.com/clubhouse-andreessen-horowitz-79107413-e83f-4203-8cfe-9f1e7dde0e2c.html"
  }, "Andreessen Horowitz leading deal for voice chat app Clubhouse - Axios")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.spooncast.net/kr/"
  }, "Spoon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://a16z.com/"
  }, "Homepage - Andreessen Horowitz")), "\n"), "\n", React.createElement(_components.h3, null, "노션 퍼스널 플랜 무료로 공개"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--notion-announced-personal-plan-for-free"
  }, "노션(Notion) 새로운 가격 정책 발표: 블럭 제한 없는 퍼스널 플랜을 무료로 제공 | 44BITS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/pricing"
  }, "Notion – Pricing")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/NotionHQ/status/1261037710665322496"
  }, "Notion on Twitter: \"Performance is a feature! That's why we spent the last month optimizing mobile start up time. ...\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/NotionHQ/status/1260691517569560578"
  }, "Notion on Twitter: \"Faster mobile apps have been a top request for us for a long time now. ...")), "\n"), "\n", React.createElement(_components.h3, null, "마이크로소프트 개발 컨퍼런스 Build 2020"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/microsoft/terminal"
  }, "microsoft/terminal: The new Windows Terminal and the original Windows console host, all in the same place!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/ko-kr/services/quantum/"
  }, "Azure Quantum | Microsoft Azure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/commandline/wsl2-will-be-generally-available-in-windows-10-version-2004/"
  }, "WSL2 will be generally available in Windows 10, version 2004 | Windows Command Line")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/commandline/the-windows-subsystem-for-linux-build-2020-summary/"
  }, "The Windows Subsystem for Linux BUILD 2020 Summary | Windows Command Line")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/microsoft/winget-cli"
  }, "microsoft/winget-cli: Windows Package Manager CLI (aka winget)")), "\n"), "\n", React.createElement(_components.h3, null, "분리발주제도 법안 통과"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/simnalamburt/status/1263416341689556993"
  }, "김지현 on Twitter: \"단계별 분리발주가 뭐냐면요. ..")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zdnet.co.kr/view/?no=20200520180528"
  }, "SW진흥법 통과, 원격개발 등 업계 숙원 대부분 담겨 - ZDNet korea")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
